@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f0f0f0;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #8f8d8b;
    border-radius: 100vh;
    border: 1px solid #f0f0f0;
  }

  .custom-range-no-thumb::-webkit-slider-thumb {
    @apply appearance-none;
    width: 0;
    height: 0;
  }

  .custom-range-no-thumb::-moz-range-thumb {
    @apply appearance-none;
    width: 0;
    height: 0;
  }

  .custom-range-no-thumb::-ms-thumb {
    @apply appearance-none;
    width: 0;
    height: 0;
  }

  /* Typography */
  .tg-h1 {
    @apply text-32 sm:text-48 font-bold leading-tight tracking-[-.02em] text-2a2928;
  }

  .tg-h2 {
    @apply text-24 sm:text-32 md:text-40 font-bold leading-8 sm:leading-10 md:leading-12 -tracking-common text-2a2928;
  }

  .tg-h3 {
    @apply md:text-32 text-24 leading-8 font-bold md:leading-tight -tracking-common text-2a2928;
  }

  .tg-body-copy {
    @apply text-16 leading-6 text-666563;
  }

  /* New Typography */
  .text-body-xxs {
    @apply text-10 font-medium leading-4 tracking-[.2px];
  }

  .text-body-xs {
    @apply text-12 font-normal leading-[18px] tracking-[.24px];
  }

  .text-body-xs-caps {
    @apply text-12 font-medium leading-4 tracking-[.6px] uppercase;
  }

  .text-body-xs-caps-500 {
    @apply text-10 font-medium leading-[14px] tracking-[.2px] uppercase;
  }

  .text-body-bold-xs {
    @apply text-12 font-medium leading-4 tracking-[.24px];
  }

  .text-body-s {
    @apply text-14 leading-5 tracking-[.28px];
  }

  .text-body-bold-s {
    @apply text-14 leading-5 tracking-[.28px] font-medium;
  }

  .text-body-m {
    @apply text-14 leading-5 tracking-[.14px];
  }

  .text-body-bold-m {
    @apply text-14 leading-5 tracking-[.14px] font-medium;
  }

  .text-body {
    @apply text-16 leading-6 tracking-[.16px];
  }

  .text-body-bold {
    @apply text-16 leading-6 tracking-[.16px] font-medium;
  }

  .text-body-l {
    @apply text-20 leading-[30px];
  }

  .text-body-bold-l {
    @apply text-20 leading-[30px] font-medium;
  }

  .text-body-xl {
    @apply text-24 leading-8;
  }

  .text-h5 {
    @apply text-20 leading-6 font-medium;
  }

  .text-h4 {
    @apply text-24 leading-8 font-medium;
  }

  .text-h3 {
    @apply text-32 leading-10 font-medium;
  }

  .text-h2 {
    @apply text-40 leading-[48px] font-medium;
  }

  .text-h1 {
    @apply text-48 leading-[56px] font-medium;
  }

  .text-h0 {
    @apply text-56 leading-[64px] font-medium;
  }

  /* Typography 2025 */
  .text-tg-h0 {
    @apply text-[64px] leading-[64px] tracking-[-1%];
  }

  .text-tg-h1 {
    @apply text-[110px] leading-[105px] tracking-[-1%];
  }

  .text-tg-h2 {
    @apply text-[36px] tracking-[-1%];
  }

  .text-tg-h3 {
    @apply text-[24px];
  }

  .text-tg-body-l {
    @apply text-[18px] leading-[23px];
  }

  .text-tg-body-m {
    @apply text-[15px] leading-[20px];
  }

  .text-tg-body-s {
    @apply text-[11px] tracking-[-1%];
  }

  .text-tg-body-xs {
    @apply text-[8px] leading-[11px];
  }

  .text-button {
    @apply text-[13px] font-semibold tracking-[6%];
  }

  .text-detail {
    @apply text-[10px] font-semibold tracking-[6%];
  }

  /* Grid */
  .grid-base {
    @apply grid w-full max-w-[1200px] grid-cols-4 gap-4 md:grid-cols-12 md:gap-6;
  }
}

html {
  @apply scroll-smooth;
}

:root {
  @apply [--outer-width:_theme(maxWidth.3xl)] [--outer-padding:_theme(spacing.6)] [--inner-gap-x:_theme(spacing.4)] [--track-number:_6] sm:[--outer-padding:_theme(spacing.10)] sm:[--inner-gap-x:_theme(spacing.6)] md:[--outer-width:_theme(maxWidth.8xl)] md:[--inner-gap-x:_theme(spacing.6)] md:[--track-number:_12] xl:[--outer-padding:_theme(spacing.40)];
}

body {
  @apply font-main antialiased [text-rendering:_optimizeLegibility];
}

[data-aos='single-line-fade-up'] {
  opacity: 0;
  transform: translateY(20px);
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}

.tooltip-container .tooltip-padding {
  padding: 4px 8px;
}

.tooltip-container .tooltip-show {
  opacity: 1;
}

.tooltip-container .tooltip-background {
  background-color: #222b27;
}
